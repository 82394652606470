import { useState } from 'react'
import AddUser from './addUser'
import FollowUp from './follow-up'

const MejorAno10Dias = () => {
    const [status, setStatus] = useState(0)

    return (
        <div className="w-full h-height-main bg-mejor10 bg-no-repeat bg-cover px-4 relative mini:pb-10 xl:pb-0">
            <p className="mini:text-xl xl:text-4xl font-pacifico font-bold text-white italic w-full text-center py-5 drop-shadow-lg">
                Tu mejor año en 10 días
            </p>
            {status === 0 ? (
                <div className="flex w-full gap-5 justify-center pt-20">
                    <div
                        className="bg-purple-300 rounded-lg shadow-md flex flex-col items-center gap-3 p-4 cursor-pointer hover:bg-purple-400 hover:scale-105 duration-200 active:scale-95"
                        onClick={() => setStatus(1)}
                    >
                        <img src={require('../../assets/images/mejor10/new_user.png')} alt="" className="" />
                        <p className="text-lg font-montserrat text-white">Nuevo Cliente</p>
                    </div>
                    <div
                        className="bg-purple-300 rounded-lg shadow-md flex flex-col items-center gap-3 p-4 cursor-pointer hover:bg-purple-400 hover:scale-105 duration-200 active:scale-95"
                        onClick={() => setStatus(2)}
                    >
                        <img src={require('../../assets/images/mejor10/user.png')} alt="" className="" />
                        <p className="text-lg font-montserrat text-white">Seguimiento Cliente</p>
                    </div>
                    <div className="bg-purple-300 rounded-lg shadow-md flex flex-col items-center gap-3 p-4 cursor-pointer hover:bg-purple-400 hover:scale-105 duration-200 active:scale-95">
                        <img src={require('../../assets/images/mejor10/users.png')} alt="" className="" />
                        <p className="text-lg font-montserrat text-white">Listado de Clientes</p>
                    </div>
                </div>
            ) : null}
            {status === 1 ? <AddUser setStatus={setStatus} /> : null}
            {status === 2 ? <FollowUp setStatus={setStatus} /> : null}
        </div>
    )
}

export default MejorAno10Dias
