import { AllCardsType } from '../../../data/all-cards'
import { DataType, DataTypeVidaPasada } from '../../../interfaces/utils'

export const interpret = (cards: AllCardsType[], data: DataTypeVidaPasada, user: string, type: string) => {
    let consult = `Por favor realiza la interpretación de la tirada basada en el libro "El mejor año de tu vida empieza hoy" de Jinny Ditzler, los más detallada y extensa posible que realizaste para ${
        data.consultante.name
    }, teniendo en cuenta su signo de ${
        data.consultante.zodiac
    } y teniendo en cuenta también los significados de las combinaciones de las cartas, con la siguiente distribuición: ${
        cards[0].name
    }${cards[0].position === 'invertida' ? ' invertida' : ''}, ${cards[1].name}${
        cards[1].position === 'invertida' ? ' invertida' : ''
    }, ${cards[2].name}${cards[2].position === 'invertida' ? ' invertida' : ''}, ${cards[3].name}${
        cards[3].position === 'invertida' ? ' invertida' : ''
    } y ${cards[4].name}${
        cards[4].position === 'invertida' ? ' invertida' : ''
    } para las cosas logradas por el consultante en el año que pasó; ${cards[5].name}${
        cards[5].position === 'invertida' ? ' invertida' : ''
    }, ${cards[6].name}${cards[6].position === 'invertida' ? ' invertida' : ''} , ${cards[7].name}${
        cards[7].position === 'invertida' ? ' invertida' : ''
    } y ${cards[8].name}${cards[8].position === 'invertida' ? ' invertida' : ''} y ${cards[9].name}${
        cards[9].position === 'invertida' ? ' invertida' : ''
    } para las cosas no logradas por el consultante también el año que pasó; ${cards[10].name}${
        cards[10].position === 'invertida' ? ' invertida' : ''
    }, ${cards[11].name}${cards[11].position === 'invertida' ? ' invertida' : ''}, ${cards[12].name}${
        cards[12].position === 'invertida' ? ' invertida' : ''
    }, ${cards[13].name}${cards[13].position === 'invertida' ? ' invertida' : ''} y ${cards[14].name}${
        cards[14].position === 'invertida' ? ' invertida' : ''
    } para las lecciones aprendidas o que debería aprender el consultante; ${cards[15].name}${
        cards[15].position === 'invertida' ? ' invertida' : ''
    } para las limitaciones internas que han influencia (miedos y creencias), ${cards[16].name}${
        cards[16].position === 'invertida' ? ' invertida' : ''
    } para las limitaciones externas que han influencia (circunstancias, entorno); ${cards[17].name}${
        cards[17].position === 'invertida' ? ' invertida' : ''
    }, ${cards[18].name}${cards[18].position === 'invertida' ? ' invertida' : ''} y ${cards[19].name}${
        cards[19].position === 'invertida' ? ' invertida' : ''
    } para los cambios personales que el consultante debe hacer, en lo emocional, en lo mental y cual es la acción concreta que debe realizar, brindandole un detallado plan de acción concreto para el siguiente año y cuáles serían las metas más importantes que perseguir el próximo año acorde a sus valores. ${
        data.consulta.length > 0 ? 'Ten en cuenta en toda esta lectura lo siguiente:' + data.consulta + '.' : ''
    } Dirígete directamente al consultante, no utilices la tercera persona refiriendote a la consultante, aún cuando las consultas se encuentren en tercera persona, adapta la respuesta para que quede en segunda persona. Recuerda que el idioma utilizado es el español de Argentia.`

    let info = {
        consult: consult,
        user: user,
        clientName: data.consultante.name,
        clientAnswer: data.consulta,
        cards: cards,
        type: type,
    }

    return info
}
