import { useDispatch, useSelector } from 'react-redux'
import { data } from '../../data/mejor'
import { copyResponse, CopyVideo, shareVideo } from '../../utils/functions'
import { Dispatch } from 'redux'
import { SetControl } from '../../store/actions'
import { useEffect, useState } from 'react'
const video = require('../../assets/vids/three.mp4')
const audio = require('../../assets/audio/03.mp3')

const DayThree = () => {
    const { client } = useSelector((state: any) => state)
    const dispatch: Dispatch<any> = useDispatch()
    const [videoFile, setVideoFile] = useState<any>(null)

    const initVideo = async () => {
        const response = await fetch(video)
        const videoBlob = await response.blob()
        const file = new File([videoBlob], 'Video motivacional día 3.mp4', { type: 'video/mp4' })
        setVideoFile(file)
    }

    const handleControl = (item: string) => {
        dispatch(SetControl(client.objectId, client.control, 2, item, !client.control[2][item]))
    }

    useEffect(() => {
        initVideo()
    }, [])

    return (
        <div className="w-1/2 p-5 flex flex-col h-[62vh] overflow-y-scroll">
            <div className="w-full text-center bg-purple-600 text-white font-bold py-2 mb-5">DIA 3</div>
            <div className="flex gap-5">
                <video controls width={200}>
                    <source src={video} type="video/mp4" />
                </video>
                <div className="flex flex-col gap-2 border-r-2 pr-5">
                    <button
                        className="py-2 px-5 bg-purple-600 text-white hover:scale-105 rounded-lg"
                        onClick={() => shareVideo(videoFile, 'Video día 3')}
                    >
                        Compartir Video
                    </button>
                    <button
                        className={`py-2 text-white hover:scale-105 rounded-lg ${
                            client.control[2].video === true ? 'bg-green-500' : 'bg-red-500'
                        }`}
                        onClick={() => handleControl('video')}
                    >
                        Compartido
                    </button>
                </div>
                <div className="felx flex-col gap-5">
                    <audio src={audio} controls className="mb-5"></audio>
                    <div className="flex gap-2">
                        <button className="py-2 px-5 bg-purple-500 text-white hover:scale-105 rounded-lg">
                            Copiar Meditación
                        </button>
                        <button
                            className={`py-2 px-5 text-white hover:scale-105 rounded-lg ${
                                client.control[2].audio === true ? 'bg-green-500' : 'bg-red-500'
                            }`}
                            onClick={() => handleControl('audio')}
                        >
                            Compartido
                        </button>
                    </div>
                </div>
            </div>
            <hr className="my-5" />
            <div className="flex flex-col gap-3">
                <p className="text-lg font-bold">Indicaciones</p>
                <textarea name="indications" id="" className="" value={data[2].indication} rows={10}></textarea>
                <div className="flex gap-5">
                    <button
                        className="py-2 px-5 bg-purple-500 text-white hover:scale-105 rounded-lg"
                        onClick={() => copyResponse(data[2].indication)}
                    >
                        Copiar Indicaciones
                    </button>
                    <button
                        className={`py-2 px-5 text-white hover:scale-105 rounded-lg ${
                            client.control[2].indications === true ? 'bg-green-500' : 'bg-red-500'
                        }`}
                        onClick={() => handleControl('indications')}
                    >
                        Compartido
                    </button>
                </div>
            </div>
            <hr className="my-5" />
            <div className="flex flex-col gap-3">
                <p className="text-lg font-bold">Motivación Extra</p>
                <textarea name="indications" id="" className="" value={data[2].extra} rows={10}></textarea>
                <div className="flex gap-5">
                    <button
                        className="py-2 px-5 bg-purple-500 text-white hover:scale-105 rounded-lg"
                        onClick={() => copyResponse(data[2].extra)}
                    >
                        Copiar Extra
                    </button>
                    <button
                        className={`py-2 px-5 text-white hover:scale-105 rounded-lg ${
                            client.control[2].extra === true ? 'bg-green-500' : 'bg-red-500'
                        }`}
                        onClick={() => handleControl('extra')}
                    >
                        Compartido
                    </button>
                </div>
            </div>
            <hr className="my-5" />
            <div className="flex flex-col gap-3">
                <p className="text-lg font-bold">Razones científicas</p>
                <textarea name="indications" id="" className="" value={data[2].razones} rows={10}></textarea>
                <div className="flex gap-5">
                    <button
                        className="py-2 px-5 bg-purple-500 text-white hover:scale-105 rounded-lg"
                        onClick={() => copyResponse(data[2].razones)}
                    >
                        Copiar razones
                    </button>
                    <button
                        className={`py-2 px-5 text-white hover:scale-105 rounded-lg ${
                            client.control[2].razones === true ? 'bg-green-500' : 'bg-red-500'
                        }`}
                        onClick={() => handleControl('razones')}
                    >
                        Compartido
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DayThree
