import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { SaveClient } from '../../store/actions'
import Swal from 'sweetalert2'

const AddUser = ({ setStatus }: { setStatus: (value: number) => void }) => {
    const dispatch: Dispatch<any> = useDispatch()
    const [data, setData] = useState({
        name: '',
        date: '',
        phone: '',
        email: '',
        obs: '',
    })

    const handleInputs = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        setData({
            ...data,
            [event?.target.name]: event?.target.value,
        })
    }

    const Save = () => {
        dispatch(SaveClient(data))
        setStatus(0)
    }

    console.log('data:', data)

    return (
        <div className="bg-purple-300 rounded-lg shadow-md p-10 flex flex-col gap-5 mx-20">
            <div className="flex gap-4">
                <label htmlFor="name" className="w-1/3 flex flex-col text-gray-600 text-lg">
                    Apellido y nombres
                    <input
                        type="text"
                        name="name"
                        value={data.name}
                        onChange={(e) => handleInputs(e)}
                        className="h-10 w-full pl-3 rounded-lg text-base text-gray-400 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                    />
                </label>
                <label htmlFor="date" className="w-fit flex flex-col text-gray-600 text-lg">
                    Nacimiento
                    <input
                        type="date"
                        name="date"
                        value={data.date}
                        onChange={(e) => handleInputs(e)}
                        className="h-10 w-fit pl-3 rounded-lg text-base text-gray-400 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                    />
                </label>
                <label htmlFor="phone" className="w-fit flex flex-col text-gray-600 text-lg">
                    Móvil
                    <input
                        type="text"
                        name="phone"
                        value={data.phone}
                        onChange={(e) => handleInputs(e)}
                        className="h-10 w-fit pl-3 rounded-lg text-base text-gray-400 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                    />
                </label>
                <label htmlFor="email" className="w-1/3 flex flex-col text-gray-600 text-lg">
                    Email
                    <input
                        type="email"
                        name="email"
                        value={data.email}
                        onChange={(e) => handleInputs(e)}
                        className="h-10 w-full pl-3 rounded-lg text-base text-gray-400 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                    />
                </label>
            </div>
            <label htmlFor="obs" className="w-full text-gray-600 text-lg flex flex-col">
                Observaciones y anotaciones
                <textarea
                    name="obs"
                    value={data.obs}
                    rows={15}
                    className="p-3 rounded-lg text-base text-gray-400  focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                    onChange={(e) => handleInputs(e)}
                ></textarea>
            </label>
            <div className="flex justify-end gap-4">
                <button
                    className="h-10 rounded-lg bg-slate-400 px-5 hover:scale-110 active:scale-95"
                    onClick={() => setStatus(0)}
                >
                    Cancelar
                </button>
                <button className="h-10 rounded-lg bg-purple-500 px-5 hover:scale-110 active:scale-95" onClick={Save}>
                    Guardar
                </button>
            </div>
        </div>
    )
}

export default AddUser
