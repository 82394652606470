import { useDispatch, useSelector } from 'react-redux'
import { data } from '../../data/mejor'
import { copyResponse, CopyVideo } from '../../utils/functions'
import { Dispatch } from 'redux'
import { SetControl } from '../../store/actions'
const video = require('../../assets/vids/two.mp4')
const audio = require('../../assets/audio/02.mp3')

const DayTwo = () => {
    const { client } = useSelector((state: any) => state)
    const dispatch: Dispatch<any> = useDispatch()

    const handleControl = (item: string) => {
        dispatch(SetControl(client.objectId, client.control, 1, item, !client.control[1][item]))
    }

    return (
        <div className="w-1/2 p-5 flex flex-col h-[62vh] overflow-y-scroll">
            <div className="w-full text-center bg-purple-600 text-white font-bold py-2 mb-5">DIA 2</div>
            <div className="flex gap-5">
                <video controls width={200}>
                    <source src={video} type="video/mp4" />
                </video>
                <div className="flex flex-col gap-2 border-r-2 pr-5">
                    <button
                        className="py-2 px-5 bg-purple-600 text-white hover:scale-105 rounded-lg"
                        onClick={() => CopyVideo(video)}
                    >
                        Copiar Video
                    </button>
                    <button
                        className={`py-2 text-white hover:scale-105 rounded-lg ${
                            client.control[1].video === true ? 'bg-green-500' : 'bg-red-500'
                        }`}
                        onClick={() => handleControl('video')}
                    >
                        Compartido
                    </button>
                </div>
                <div className="felx flex-col gap-5">
                    <audio src={audio} controls className="mb-5"></audio>
                    <div className="flex gap-2">
                        <button className="py-2 px-5 bg-purple-500 text-white hover:scale-105 rounded-lg">
                            Copiar Meditación
                        </button>
                        <button
                            className={`py-2 px-5 text-white hover:scale-105 rounded-lg ${
                                client.control[1].audio === true ? 'bg-green-500' : 'bg-red-500'
                            }`}
                            onClick={() => handleControl('audio')}
                        >
                            Compartido
                        </button>
                    </div>
                </div>
            </div>
            <hr className="my-5" />
            <div className="flex flex-col gap-3">
                <p className="text-lg font-bold">Indicaciones</p>
                <textarea name="indications" id="" className="" value={data[1].indication} rows={10}></textarea>
                <div className="flex gap-5">
                    <button
                        className="py-2 px-5 bg-purple-500 text-white hover:scale-105 rounded-lg"
                        onClick={() => copyResponse(data[1].indication)}
                    >
                        Copiar Indicaciones
                    </button>
                    <button
                        className={`py-2 px-5 text-white hover:scale-105 rounded-lg ${
                            client.control[1].indications === true ? 'bg-green-500' : 'bg-red-500'
                        }`}
                        onClick={() => handleControl('indications')}
                    >
                        Compartido
                    </button>
                </div>
            </div>
            <hr className="my-5" />
            <div className="flex flex-col gap-3">
                <p className="text-lg font-bold">Motivación Extra</p>
                <textarea name="indications" id="" className="" value={data[1].extra} rows={10}></textarea>
                <div className="flex gap-5">
                    <button
                        className="py-2 px-5 bg-purple-500 text-white hover:scale-105 rounded-lg"
                        onClick={() => copyResponse(data[1].extra)}
                    >
                        Copiar Extra
                    </button>
                    <button
                        className={`py-2 px-5 text-white hover:scale-105 rounded-lg ${
                            client.control[1].extra === true ? 'bg-green-500' : 'bg-red-500'
                        }`}
                        onClick={() => handleControl('extra')}
                    >
                        Compartido
                    </button>
                </div>
            </div>
            <hr className="my-5" />
            <div className="flex flex-col gap-3">
                <p className="text-lg font-bold">Razones científicas</p>
                <textarea name="indications" id="" className="" value={data[1].razones} rows={10}></textarea>
                <div className="flex gap-5">
                    <button
                        className="py-2 px-5 bg-purple-500 text-white hover:scale-105 rounded-lg"
                        onClick={() => copyResponse(data[1].razones)}
                    >
                        Copiar razones
                    </button>
                    <button
                        className={`py-2 px-5 text-white hover:scale-105 rounded-lg ${
                            client.control[1].razones === true ? 'bg-green-500' : 'bg-red-500'
                        }`}
                        onClick={() => handleControl('razones')}
                    >
                        Compartido
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DayTwo
