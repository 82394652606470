import { Dispatch } from 'redux'
import { AllCards, AllCardsType } from '../../data/all-cards'
import {
    ERROR,
    ERROR_LOGIN,
    GET_ALL_CARDS,
    GET_ALL_CONSULTS,
    GET_CLIENTS,
    LOGIN,
    LOGOUT,
    RESET_CLIENT,
    RESET_CONSULT,
    RESET_ERROR,
    SAVE_ALL_CARDS,
    SAVE_CLIENT,
    SAVE_CONSULT,
    SET_CLIENT,
    SET_CONTROL,
} from '../constants'
import axios from 'axios'
import { Configuration, OpenAIApi } from 'openai'
import Swal from 'sweetalert2'

const apiKey = process.env.REACT_APP_OPENAI_API_KEY

const config = new Configuration({
    apiKey,
})
const openai = new OpenAIApi(config)

const headers = {
    'X-Parse-Application-Id': 'tBqQ1HUXXqIxWsAiGG7zl4nf8PpFEfbFKBbXxp6h',
    'X-Parse-REST-API-Key': 'O2IrlrFHVOfut3VGKzKdDawc6zBIbmWlStuRd58Y',
    'X-Parse-Revocable-Session': '1',
    'Content-Type': 'application/json',
}

export const GetAllCards = () => (dispatch: Dispatch) => {
    console.log('AllCards', AllCards)
    let response = AllCards
    dispatch({
        type: GET_ALL_CARDS,
        payload: response,
    })
}

export const SaveAllCards = (payload: AllCardsType[]) => (dispatch: Dispatch) => {
    dispatch({
        type: SAVE_ALL_CARDS,
        payload: payload,
    })
}

export const UserLogin = (payload: any) => async (dispatch: Dispatch) => {
    try {
        const response = await axios.get(
            `https://parseapi.back4app.com/login?username=${payload.username}&password=${payload.password}`,
            { headers }
        )
        dispatch({
            type: LOGIN,
            payload: response.data,
        })
    } catch (error: any) {
        dispatch({
            type: ERROR_LOGIN,
            payload: error,
        })
    }
}

export const ResetError = () => (dispatch: Dispatch) => {
    dispatch({
        type: RESET_ERROR,
        payload: null,
    })
}

export const SetUser = (payload: any) => (dispatch: Dispatch) => {
    dispatch({
        type: LOGIN,
        payload: payload,
    })
}

export const GetAllConsults = () => async (dispatch: Dispatch) => {
    try {
        const response = await axios.get('https://parseapi.back4app.com/classes/consults', { headers })
        dispatch({
            type: GET_ALL_CONSULTS,
            payload: response.data.results,
        })
    } catch (error: any) {
        dispatch({
            type: ERROR,
            payload: error.message,
        })
    }
}

export const GetConsultsByUser = (username: string) => async (dispatch: Dispatch) => {
    try {
        const response = await axios.get('https://parseapi.back4app.com/classes/consults', { headers })
        let data = response.data.results.filter((consult: any) => consult.user === username)
        dispatch({
            type: GET_ALL_CONSULTS,
            payload: data,
        })
    } catch (error: any) {
        dispatch({
            type: ERROR,
            payload: error.message,
        })
    }
}

export const Logout = () => (dispatch: Dispatch) => {
    dispatch({
        type: LOGOUT,
        payload: null,
    })
}

export const GetConsult = (value: any) => async (dispatch: Dispatch) => {
    try {
        const response = await openai.createChatCompletion({
            model: 'gpt-3.5-turbo',
            messages: [
                {
                    role: 'system',
                    content:
                        'Eres una tarotista y astróloga esotérica, intuitiva y muy cariñosa que está realizando una tirada de cartas para el tarot o tirada de monedas para el I-Ching y tu misión es buscar respuestas concretas a lo que tu consultante está consultando. También puedes realizar análisis de compatibilidad de parejas. Utiliza emoticones en las respuestas. No saludes ni te despidas en las respuestas. No te refieras al consultante en tercera persona.',
                },
                { role: 'user', content: value.consult },
            ],
            top_p: 1,
        })

        let resp: any = ''
        if (value.type === 'general') {
            response.data.choices.forEach((element) => {
                resp += element.message?.content
            })
        } else {
            resp = response.data.choices[0].message?.content
        }

        const data = {
            clientName: value.clientName,
            clientAnswer: value.clientAnswer,
            type: value.type,
            cards: value.cards,
            response: resp,
            user: value.user,
            consult: value.consult,
        }

        await axios.post('https://parseapi.back4app.com/classes/consults', data, {
            headers,
        })

        dispatch({
            type: SAVE_CONSULT,
            payload: data,
        })
    } catch (error: any) {
        dispatch({
            type: ERROR,
            payload: error.message,
        })
    }
}

export const SetConsult = (value: any) => async (dispatch: Dispatch) => {
    const data = {
        clientName: value.clientName,
        clientAnswer: value.clientAnswer,
        type: value.type,
        cards: value.cards,
        response: value.consult,
        user: value.user,
        consult: value.consult,
    }

    await axios.post('https://parseapi.back4app.com/classes/consults', data, {
        headers,
    })

    dispatch({
        type: SAVE_CONSULT,
        payload: data,
    })
}

export const ResetConsult = () => (dispatch: Dispatch) => {
    dispatch({
        type: RESET_CONSULT,
        payload: null,
    })
}

export const SaveClient = (value: any) => async (dispatch: Dispatch) => {
    try {
        console.log('value:', value)
        const arreglo = Array(10)
        arreglo.fill({ video: false, audio: false, indications: false, extra: false, razones: false })
        let data: any = {
            name: value.name,
            birthday: value.date,
            phone: value.phone,
            email: value.email,
            obs: value.obs,
            status: 'active',
            control: arreglo,
        }

        const response = await axios.post('https://parseapi.back4app.com/classes/clients', data, { headers })
        data.objectId = response.data.objectId
        Swal.fire('Cliente Guardado', 'El cliente ha sido agregado a la base de datos', 'success')

        dispatch({
            type: SAVE_CLIENT,
            payload: data,
        })
    } catch (error: any) {
        dispatch({
            type: ERROR,
            payload: error.message,
        })
    }
}

export const ResetClient = () => async (dispatch: Dispatch) => {
    dispatch({
        type: RESET_CLIENT,
        payload: null,
    })
}

export const GetClients = () => async (dispatch: Dispatch) => {
    try {
        const response = await axios.get('https://parseapi.back4app.com/classes/clients', { headers })

        dispatch({
            type: GET_CLIENTS,
            payload: response.data.results,
        })
    } catch (error: any) {
        dispatch({
            type: ERROR,
            payload: error.message,
        })
    }
}

export const SetClient = (value: any) => (dispatch: Dispatch) => {
    dispatch({
        type: SET_CLIENT,
        payload: value,
    })
}

export const SetControl =
    (clientId: string, arreglo: any, position: number, item: string, value: boolean) => async (dispatch: Dispatch) => {
        try {
            arreglo[position][item] = value
            await axios.put(
                'https://parseapi.back4app.com/classes/clients/' + clientId,
                { control: arreglo },
                { headers }
            )

            dispatch({
                type: SET_CONTROL,
                payload: arreglo,
            })
        } catch (error: any) {
            dispatch({
                type: ERROR,
                payload: error.message,
            })
        }
    }
